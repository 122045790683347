export const URL = {
  RESUME_PRO:
    "https://drive.google.com/file/d/10RZ0srkPxq2cH6pkXPLZOWtnFKIziICh/view?usp=sharing",
  RESUME:
    "https://drive.google.com/file/d/10RZ0srkPxq2cH6pkXPLZOWtnFKIziICh/view?usp=sharing",
  RESUME_PRO_:
    "https://drive.google.com/file/d/10RZ0srkPxq2cH6pkXPLZOWtnFKIziICh/view?usp=sharing",
  LINKED_IN: "https://www.linkedin.com/in/nayan-vekariya/",
  INSTAGRAM: "https://www.instagram.com/nayan_vekariya14/",
  GIT_HUB: "https://github.com/Nayan014",
  YOUTUBE: "https://www.youtube.com/@sim_danger",
  MEDIUM: "https://medium.com/@nayan.vekariya014",
  UP_WORK: "https://www.upwork.com/freelancers/~01c4ffd8f428873af2?mp_source=share"
  
};
